<div class="my-stuff-container">
  <div class="my-stuff-header">
    <div>
      <h1>My Stuff</h1>
      <h2>View your bookmarks, likes, dislikes and documents you downloaded.</h2>
      @if (!listData) {
        @if (!fetching()) {
        <div>
          You have no items.
        </div>
        }
      }
      @if (listData && listData.length > 0) {
        <div class="result-list-header" [ngClass]="{'result-list-header-sm': isSmallViewPort}">
          <kendo-textbox class="search-input" [ngClass]="{'search-input-lg': isSmallViewPort}"
            placeholder="Search within list..." (valueChange)="onFilterSearch($event)">
            <ng-template kendoTextBoxSuffixTemplate [showSeparator]="false">
              <div class="search-icon-wrapper">
                <mat-icon aria-hidden="false" aria-label="Search" fontIcon="search" />
              </div>
            </ng-template>
          </kendo-textbox>
        </div>
      }
    </div>

    <div>
      @if (listData && listData.length > 0) {
        <p>Filter your stuff to view.</p>
        <form [formGroup]="myStuffForm">
          <div class="check-bx-list">
            <div class="check-bx" kendoRippleContainer>
              <input type="checkbox" #bookmarks kendoCheckBox size="large" rounded="medium" formControlName="bookmarks"
                (click)="onFilterType()" />
              <kendo-label class="k-checkbox-label" [for]="bookmarks" text="Bookmarks"></kendo-label>
            </div>

            <div class="check-bx" kendoRippleContainer>
              <input type="checkbox" #likes kendoCheckBox size="large" rounded="medium" formControlName="likes"
                (click)="onFilterType()" />
              <kendo-label class="k-checkbox-label" [for]="likes" text="Likes"></kendo-label>
            </div>

            <div class="check-bx" kendoRippleContainer>
              <input type="checkbox" #dislikes kendoCheckBox size="large" rounded="medium" formControlName="dislikes"
                (click)="onFilterType()" />
              <kendo-label class="k-checkbox-label" [for]="dislikes" text="Dislikes"></kendo-label>
            </div>

            <div class="check-bx" kendoRippleContainer>
              <input type="checkbox" #downloads kendoCheckBox size="large" rounded="medium" formControlName="downloads"
                (click)="onFilterType()" />
              <kendo-label class="k-checkbox-label" [for]="downloads" text="Downloads"></kendo-label>
            </div>
          </div>
        </form>

        
      }
    </div>
  </div>

  @if (listData && listData.length > 0) {
  <kendo-listview [kendoListViewBinding]="listData" [pageable]="pagerSettings" [pageSize]="pageSize"
    containerClass="listview-content">
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isLast="isLast">
      <kendo-card class="result-card" width="auto">
        <kendo-card-body class="result-card-body">
          <div class="result-card-header">
            <h4 kendoCardTitle class="title">
              <div class="source k-card-subtitle">
                <span class="label">Source</span>
                @switch (dataItem['dataCard']) {
                @case (dataCardType.Post) {
                {{dataCardLabel.Post}}
                }
                @case (dataCardType.Snf) {
                {{dataCardLabel.Snf}}
                }
                @case (dataCardType.Govwin) {
                {{dataCardLabel.Govwin}}
                }
                @default {
                TBD
                }
                }
              </div>

              @switch (dataItem['documentExtension']) {
              @case ('pdf') {
              <kendo-svgicon [icon]="filePdfIcon" size="large" class="pdf-icon"></kendo-svgicon>
              }
              @case ('doc') {
              <kendo-svgicon [icon]="fileWordIcon" size="large" class="docx-icon"></kendo-svgicon>
              }
              @case ('docx') {
              <kendo-svgicon [icon]="fileWordIcon" size="large" class="docx-icon"></kendo-svgicon>
              }
              @case ('ppt') {
              <kendo-svgicon [icon]="filePptIcon" size="large" class="pptx-icon"></kendo-svgicon>
              }
              @case ('pptx') {
              <kendo-svgicon [icon]="filePptIcon" size="large" class="pptx-icon"></kendo-svgicon>
              }
              @case ('xls') {
              <kendo-svgicon [icon]="fileExcelIcon" size="large" class="xlsx-icon"></kendo-svgicon>
              }
              @case ('xlsx') {
              <kendo-svgicon [icon]="fileExcelIcon" size="large" class="xlsx-icon"></kendo-svgicon>
              }
              @case ('csv') {
              <kendo-svgicon [icon]="fileCsvIcon" size="large" class="default-icon"></kendo-svgicon>
              }
              @case ('text') {
              <kendo-svgicon [icon]="fileTxtIcon" size="large" class="default-icon"></kendo-svgicon>
              }
              @case ('mp3') {
              <kendo-svgicon [icon]="fileVideoIcon" size="large" class="default-icon"></kendo-svgicon>
              }
              @case ('zip') {
              <kendo-svgicon [icon]="fileZipIcon" size="large" class="zip-icon"></kendo-svgicon>
              }
              @case ('json') {
              <kendo-svgicon [icon]="dataJsonIcon" size="large" class="default-icon"></kendo-svgicon>
              }
              @default {
              <kendo-svgicon [icon]="fileTxtIcon" size="large" class="default-icon"></kendo-svgicon>
              }
              }
              <a href="{{dataItem['metadataStoragePath']}}" target="_blank">{{ dataItem['metadataStorageName'] }}</a>
            </h4>
          </div>

          <div>
            <hr kendoCardSeparator />
            <kendo-card-actions layout="end" orientation="horizontal">
              <comment-actions [actionsItem]="dataItem" (actionsEvent)="actionEventEmitted($event)"></comment-actions>
            </kendo-card-actions>
          </div>
        </kendo-card-body>

      </kendo-card>
    </ng-template>
    <ng-template kendoListViewFooterTemplate>
      <div class="footer-note"></div>
    </ng-template>
  </kendo-listview>
  }
</div>

@if (openPostDialog) {
<add-post-dialog></add-post-dialog>
}